import { DataLayerObject, ModuleEventObject, TrackingSettings } from '@/typings';

export default class ModuleEvent {
  /** @property {string} */
  private readonly moduleName: string;
  /** @property {string} */
  private readonly eventName: string;
  /** @property {DataLayerObject} */
  private readonly dataLayerObject: DataLayerObject;
  /** @property {TrackingSettings} */
  private readonly settings: TrackingSettings;
  /** @property {string} */
  private readonly uniqueId: string;

  /**
   * @param {string} moduleName
   * @param {string} eventName
   * @param {dataLayerObject=} dataLayerObject
   * @param {TrackingSettings=} settings
   * @param {string=} uniqueId
   *
   * @return void
   */
  constructor({moduleName, eventName, dataLayerObject = {}, settings = {sendToDataLayers: ['dataLayer']}, uniqueId = ''}: ModuleEventObject) {
    this.moduleName = moduleName;
    this.eventName = eventName;
    this.dataLayerObject = dataLayerObject;
    this.settings = settings;
    this.uniqueId = uniqueId || Math.random().toString(36).substring(2, 9);
  }

  /**
   * @param {string} jsonString
   *
   * @return ModuleEvent
   */
  public static fromJson(jsonString: string): ModuleEvent {
    return new ModuleEvent(JSON.parse(jsonString));
  }

  /**
   * @return DataLayerObject
   */
  public getDataLayerObject(): DataLayerObject {
    this.dataLayerObject.event = 'module-tracking-event';
    this.dataLayerObject.virtualPageURL = `${ window.location.origin }${ window.location.pathname.replace(/\/$/, '') }/${ this.moduleName }/${ this.eventName }`;

    return this.dataLayerObject;
  }

  /**
   * @return string
   */
  public getUniqueId(): string {
    return this.uniqueId;
  }

  public getSettings(): TrackingSettings {
    return this.settings;
  }
}
