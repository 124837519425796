import ModuleEvent from '@/modules/ModuleEvent';
import { DataLayerObject, PostMessageData } from '@/typings';

export default class Log {

  /**
   * @param {string} message
   * @param {PostMessageData|DataLayerObject|ModuleEvent=} data
   *
   * @return void
   */
  constructor(message: string, data?: PostMessageData | DataLayerObject | ModuleEvent) {
    if (window.PowerKraut.debug) {
      message = `PowerKraut Module Tracker [${ window.location.href }]: ${ message }`;

      if (data) {
        console.log(message, data);
      } else {
        console.log(message);
      }
    }
  }
}
