import ModuleTracking from '@/ModuleTracking';
import ModuleEvent from '@/modules/ModuleEvent';


window.PowerKraut = window.PowerKraut || {};
window.PowerKraut.ModuleTracking = ModuleTracking;
window.PowerKraut.ModuleEvent = ModuleEvent;

ModuleTracking.watchEvents();

export default ModuleTracking;
