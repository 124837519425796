import Log from '@/modules/Log';
import ModuleTracking from '@/ModuleTracking';
import ModuleEvent from '@/modules/ModuleEvent';
import { ModuleMessageEvent, ModuleMessageInterface, PostMessageData } from '@/typings';

export default class ModuleMessage implements ModuleMessageInterface {
  /** @property {string} */
  private static senderName = 'PowerKrautModuleTracking';
  /** @property {Array<ModuleEvent>} */
  private events: Array<ModuleEvent> = [];

  /**
   * @return void
   */
  private constructor() {
    //silence
  }

  /**
   * @return ModuleMessageInterface
   */
  public static getInstance(): ModuleMessageInterface {
    if (!window.PowerKraut.ModuleTrackingMessage) {
      window.PowerKraut.ModuleTrackingMessage = new ModuleMessage();
    }

    return window.PowerKraut.ModuleTrackingMessage;
  }

  /**
   * @param {moduleEvent} moduleEvent
   *
   * @return void
   */
  public static sendToParent(moduleEvent: ModuleEvent): void {
    const data: PostMessageData = {
      data  : JSON.stringify(moduleEvent),
      sender: ModuleMessage.senderName,
    };
    new Log('Sending event to parent', data);

    window.parent.postMessage(data, '*');
  }

  /**
   * @return void
   */
  public receive(): void {
    window.addEventListener('message', ({data}: ModuleMessageEvent): void => {
      if (data?.sender === ModuleMessage.senderName) {
        const moduleEvent: ModuleEvent = ModuleEvent.fromJson(data.data);

        if (!this.hasReceivedMessageBefore(moduleEvent)) {
          new Log('Received event from child', data);

          new ModuleTracking().sendModuleEvent(moduleEvent);
        } else {
          new Log('Received duplicate event from child', data);
        }
      }
    });
  }

  /**
   * @param {moduleEvent} moduleEvent
   *
   * @return boolean
   */
  private hasReceivedMessageBefore(moduleEvent: ModuleEvent): boolean {
    for (let index = 0; index < this.events.length; index++) {
      if (this.events[index].getUniqueId() === moduleEvent.getUniqueId()) {
        return true;
      }
    }

    this.events.push(moduleEvent);

    return false;
  }
}
