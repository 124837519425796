import Message from '@/modules/ModuleMessage';
import TagManager from '@/modules/TagManager';
import ModuleEvent from '@/modules/ModuleEvent';
import Log from '@/modules/Log';
import { SendEventObject, WatchSettingsObject } from '@/typings';

export default class ModuleTracking {
  /**
   * @param {ModuleEvent} moduleEvent
   *
   * @return void
   */
  public sendModuleEvent(moduleEvent: ModuleEvent): void {
    if (window.parent === window) {
      new Log('Sending event to Tag Manager', moduleEvent);

      TagManager.getInstance().send(moduleEvent);
    } else {
      Message.sendToParent(moduleEvent);
    }
  }

  /**
   * @param {string} moduleName
   * @param {string} eventName
   * @param {DataLayerObject=} dataLayer
   * @param {TrackingSettings=} settings
   *
   * @return void
   */
  public static sendEvent({moduleName, eventName, dataLayerObject = {}, settings = {sendToDataLayers: ['dataLayer']}}: SendEventObject): void {
    const moduleEvent: ModuleEvent = new ModuleEvent({moduleName, eventName, dataLayerObject, settings})
    return new ModuleTracking().sendModuleEvent(moduleEvent);
  }

  /**
   * @param {string} moduleName
   * @param {string} eventName
   * @param {DataLayerObject | undefined} dataLayerObject
   * @param {TrackingSettings | undefined} settings
   * @param {WatchSettingsObject} watchSettings
   */
  public static sendEventOnInteraction({moduleName, eventName, dataLayerObject = {}, settings = {sendToDataLayers: ['dataLayer']}}: SendEventObject, watchSettings: WatchSettingsObject): void {
    const {container = document, events = ['click', 'keydown']} = watchSettings ?? {};
    let firstInteraction = false;

    events.forEach((event: string): void => {
      container.addEventListener(event, (): void => {
        if (!firstInteraction) {
          ModuleTracking.sendEvent({moduleName, eventName, dataLayerObject, settings});
          firstInteraction = true;
        }
      })
    })
  }

  /**
   * @return void
   */
  public static watchEvents(): void {
    Message.getInstance().receive();
  }
}
