import Log from '@/modules/Log';
import ModuleEvent from '@/modules/ModuleEvent';
import { DataLayerObject } from '@/typings';

export default class TagManager {
  /** @property TagManager*/
  private static instance: TagManager
  /** @property Array<ModuleEvent> */
  private eventQueue: Array<ModuleEvent> = [];

  /**
   * @return void
   */
  private constructor() {
    this.waitForTracker()
      .then(() => this.sendQueuedEvents())
      .catch(() => new Log('Tag Manager not found, stopping...'));
  }

  /**
   * @return TagManager
   */
  public static getInstance(): TagManager {
    if (!TagManager.instance) {
      TagManager.instance = new TagManager();
    }

    return TagManager.instance;
  }

  /**
   * @param {ModuleEvent} moduleEvent
   *
   * @return void
   */
  public send(moduleEvent: ModuleEvent): void {
    if (this.isTrackerActive()) {
      this.sendEvent(moduleEvent);
    } else {
      this.eventQueue.push(moduleEvent);
    }
  }

  /**
   * @return void
   */
  private sendQueuedEvents(): void {
    this.eventQueue.forEach((event: ModuleEvent): void => {
      this.sendEvent(event);
    })
  }

  /**
   * @param {ModuleEvent} moduleEvent
   *
   * @return void
   */
  private sendEvent(moduleEvent: ModuleEvent): void {
    const dataLayerObject: DataLayerObject = moduleEvent.getDataLayerObject();

    this.getDataLayers(moduleEvent).forEach((dataLayer: string): void => {
      window[dataLayer] = window[dataLayer] || [];
      window[dataLayer].push(dataLayerObject);

      new Log(`Send event to: ${ dataLayer }`, dataLayerObject);
    });
  }

  /**
   * @return Array<string>
   */
  private getDataLayers(moduleEvent: ModuleEvent): Array<string> {
    const sendToDataLayers: Array<string> | 'all' | undefined = moduleEvent.getSettings().sendToDataLayers;

    if (typeof sendToDataLayers === 'undefined') {
      return ['dataLayer'];
    } else if (sendToDataLayers === 'all') {
      const dataLayerNames: Array<string> = [];
      const dataLayers: Array<string> = [];
      for (const key in window.google_tag_manager) {
        if (dataLayerNames.indexOf(key) < 0 && window.google_tag_manager[key] && window.google_tag_manager[key].dataLayer) {
          dataLayerNames.push(key);
        }
      }

      dataLayerNames.forEach((name: string): void => {
        const dataLayer = window.google_tag_manager[name].dataLayer.name;
        if (dataLayers.indexOf(dataLayer) < 0) {
          dataLayers.push(dataLayer);
        }
      });

      return dataLayers;
    } else {
      return sendToDataLayers;
    }

  }

  /**
   * @return void
   */
  private waitForTracker(): Promise<unknown> {
    let timeout = false;
    setTimeout(() => timeout = true, 10000);

    return new Promise((resolve, reject): void => {
      const isLoaded = () => {
        if (this.isTrackerActive()) {
          resolve(true)
        } else {
          if (timeout) {
            reject(false);
          } else {
            new Log('Tag Manager inactive, waiting 1 second');
            setTimeout(() => isLoaded(), 1000)
          }
        }
      }

      isLoaded();
    });
  }

  /**
   * @return boolean
   */
  private isTrackerActive(): boolean {
    return typeof window.google_tag_manager === 'object';
  }
}
